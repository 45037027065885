import { createBrowserRouter } from 'react-router-dom';
import RegistrationMaintain from './routes/registration-maintain.route';
import LoginTwoFactor from './routes/login.twofactor.route';
import Verification from './routes/verification.route';
import Login, { loginLoader } from './routes/login.route';
import RegistrationPersonal, {
	registrationPersonalLoader
} from './routes/registration-personal.route';
import RegistrationEmailCode from './routes/registration-email-code.route';
import RegistrationPhone from './routes/registration-phone.route';
import RegistrationPhoneCode from './routes/registration-phone-code.route';
import { ROUTES } from './types/routes';
import LoginImpersonate, {
	impersonateLoader
} from './routes/login.impersonate.route';
import Redirect from './routes/redirect.route';
import LoginOTP from './routes/login.otp.route';
import VerificationSumSub, {
	verificationSumSubLoader
} from './routes/verification.sumsub.route';
import Withdraw, { withdrawLoader } from './routes/withdraw.route';
import WithdrawSummary from './routes/withdraw.summary';
import WithdrawTwoFa from './routes/withdraw.twofactor.route';
import Root, { rootLoader } from './routes/root.route';
import LoginSelectTwoFa from './routes/login.selectwofa.route';
import LoginRecovery from './routes/login.recovery.route';
import LoginNewPasswordRecovery from './routes/login.recovery.new-password.route';
import LoginConfirmRecovery from './routes/login.recovery.confirm.route';
import ErrorLayout from './components/error';
import Questionary, {
	verificationQuestionaryLoader
} from './routes/verification.questionary.route';
import LoginSetupTwoFa from './routes/login.setup-two-fa.route';
import LoginSecretKey, {
	loginSecretKeyLoader
} from './routes/login.secret-key.route';
import LoginConfirmTwoFa from './routes/login.confirm-two-fa.route';
import VerificationWait from './routes/verification.wait.route';
import Document from './routes/document.route';

export const router = createBrowserRouter([
	{
		path: ROUTES.LOADING.INDEX,
		loader: rootLoader,
		element: <Root />,
		errorElement: <ErrorLayout />
	},
	{
		path: ROUTES.REGISTRATION.INDEX,
		loader: registrationPersonalLoader,
		element: <RegistrationPersonal />,
		errorElement: <ErrorLayout />
	},
	{
		path: ROUTES.REGISTRATION.EMAIL_CODE,
		element: <RegistrationEmailCode />
	},
	{
		path: ROUTES.REGISTRATION.PHONE,
		element: <RegistrationPhone />
	},
	{
		path: ROUTES.REGISTRATION.PHONE_CODE,
		element: <RegistrationPhoneCode />
	},
	{
		path: ROUTES.REGISTRATION.MAINTAIN,
		element: <RegistrationMaintain />
	},
	{
		path: ROUTES.LOGIN.INDEX,
		element: <Login />,
		loader: loginLoader,
		errorElement: <ErrorLayout />
	},
	{
		path: ROUTES.LOGIN.TWO_FA,
		element: <LoginTwoFactor />
	},
	{
		path: ROUTES.LOGIN.OTP,
		element: <LoginOTP />
	},
	{
		path: ROUTES.LOGIN.SELECT_TWOFA,
		element: <LoginSelectTwoFa />
	},
	{
		path: ROUTES.LOGIN.IMPERSONATE,
		loader: impersonateLoader,
		element: <LoginImpersonate />
	},
	{
		path: ROUTES.LOGIN.PASSWORD_RECOVERY,
		element: <LoginRecovery />
	},
	{
		path: ROUTES.LOGIN.NEW_PASSWORD,
		element: <LoginNewPasswordRecovery />
	},
	{
		path: ROUTES.LOGIN.CONFIRM_EMAIL,
		element: <LoginConfirmRecovery />
	},
	{
		path: ROUTES.LOGIN.SETUP_TWOFA,
		element: <LoginSetupTwoFa />
	},
	{
		loader: loginSecretKeyLoader,
		path: ROUTES.LOGIN.GET_KEY,
		element: <LoginSecretKey />
	},
	{
		path: ROUTES.LOGIN.CONFIRM_TWOFA,
		element: <LoginConfirmTwoFa />
	},
	{
		path: ROUTES.VERIFICATION.INDEX,
		element: <Verification />
	},
	{
		path: ROUTES.VERIFICATION.SUMSUB,
		loader: verificationSumSubLoader,
		element: <VerificationSumSub />,
		errorElement: <ErrorLayout />
	},
	{
		path: ROUTES.VERIFICATION.QUESTIONARY,
		loader: verificationQuestionaryLoader,
		element: <Questionary />
	},
	{
		path: ROUTES.VERIFICATION.WAIT,
		element: <VerificationWait />
	},
	{
		path: ROUTES.WITHDRAW.INDEX,
		loader: withdrawLoader,
		element: <Withdraw />,
		errorElement: <ErrorLayout />
	},
	{
		path: ROUTES.WITHDRAW.SUMMARY,
		element: <WithdrawSummary />
	},
	{
		path: ROUTES.COMMON.DOCUMENT,
		element: <Document />
	},
	{
		path: ROUTES.WITHDRAW.TWOFA,
		element: <WithdrawTwoFa />
	},
	{
		path: ROUTES.REDIRECT.INDEX,
		element: <Redirect />
	}
]);

import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TWStateInfo } from '../../types/types';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { CustomButton } from '../common/buttons';
import { useRejectMutation, useSendOtpCodeMutation } from '../../data/mutations/payments';
import { ParamContext } from '../../data/param.provider';
import { CustomInput } from '../common/inputs';
import { OTPModal } from '../common/modals';
import { ROUTES } from '../../types/routes';

const WithdrawLayout = () => {
	const { t } = useTranslation();

	const { wState } = useContext(ParamContext);

	const buttonRef = useRef(null);

	const navigate = useCustomNavigate();

	const { reject, isLoading } = useRejectMutation(navigate);

	const { sendOtpCode, isPending, isError, error } = useSendOtpCodeMutation(navigate);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const [sessionWState, setSessionWState] = useState<TWStateInfo | undefined>(
		undefined
	);

	const [otp, setOTP] = useState({ key: '', value: '' });

	const identityTypes = JSON.parse(sessionStorage.getItem('identityTypes') as string);

	useEffect(() => {
		const sessionIdentityTypes = JSON.parse(
			sessionStorage.getItem('identityTypes') as string
		);
		if (sessionIdentityTypes && sessionIdentityTypes?.length === 1) {
			setOTP({
				key: sessionIdentityTypes[0].key,
				value: sessionIdentityTypes[0].value,
			});
		}
		const wStateInfo = JSON.parse(sessionStorage.getItem('wStateInfo') as string);
		setSessionWState(wStateInfo as TWStateInfo);
		if (wStateInfo?.status?.toLocaleLowerCase() === 'done') {
			navigate(ROUTES.REDIRECT.INDEX);
		}
	}, []);

	const handleReject = async () => {
		reject({ wstate: wState });
	};

	const handleSubmit = async () => {
		sendOtpCode({
			wstate: wState,
			identityType: otp.key,
			navigate: navigate,
		});
	};

	const handleOTPSelect = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleSelectClick = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleOTPChange = (value: { key: string; value: string }) => {
		setOTP({ key: value.key, value: value.value });
	};

	const parseParamValue = (paramValue: string) => {
		if(paramValue) {
			if (paramValue === 'withdrawCrypto') {
				return t('withdrawCrypto');
			} else if (
				paramValue?.includes('Bank') &&
				paramValue?.includes('withdraw')
			) {
				return t('withdrawBank');
			} else {
				return paramValue;
			}
		}
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<OTPModal
					isModalOpen={isModalOpen}
					handleIsModalOpen={handleOTPSelect}
					handleItemClick={handleOTPChange}
				/>
				<h1 className={'confirmEmailText'}>{t('withdrawIndexHeader')}</h1>
				<div className={'confirmWithdrawContainer'}>
					{sessionWState?.visualParams.map((param) =>
						param.paramValue ? (
							<>
								<div key={param.paramId} className="paramField">
									<h1 className="paramKey">{t(param?.paramId)}:</h1>
									<h1 className="paramValue">
										{parseParamValue(String(param?.paramValue))}
									</h1>
								</div>
								{param.paramId === 'exchangeRate' ? (
									<>
										<div key={param?.paramId} className="paramField">
											<h1 className="paramKey">
												{t('exchangeWarningRateText')}:
											</h1>
										</div>
									</>
								) : (
									''
								)}
							</>
						) : (
							''
						)
					)}
				</div>
				{identityTypes?.length > 1 ? (
					<CustomInput
						isRequired={otp.key === '' ? false : true}
						type={'text'}
						id={'otpSelect'}
						label={
							otp.key === '' ? t('loginSelectTwoFaHeader') : t('otpHeader')
						}
						value={otp.key}
						onClearClick={handleSelectClick}
						className={'otpSelect'}
						isSelectOtpCode={true}
						imgUrl={''}
					/>
				) : (
					''
				)}
				{otp.key === 'FIDO2' ? (
					<h1 className={'loginErrorText'}>{t('TWOFASelectWrongOption')}</h1>
				) : (
					''
				)}
				{isError ? (
					<h1 className={'errorTextContainer'}>
						{
							(error as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
						}
					</h1>
				) : (
					<div className={'errorText'} />
				)}
				<div className={'confirmButtonContainer'}>
					<CustomButton
						isGrey
						buttonRef={buttonRef}
						isLoading={isLoading}
						text={t('withdrawIndexDenyButton') as string}
						type={'submit'}
						onClick={handleReject}
						className={'confirmButton'}
						isDisabled={false}
					/>
					<CustomButton
						buttonRef={buttonRef}
						isLoading={isPending}
						text={t('withdrawIndexApproveButton') as string}
						type={'submit'}
						onClick={handleSubmit}
						className={'confirmButton'}
						isDisabled={otp.key === '' || otp.key === 'FIDO2'}
					/>
				</div>
			</div>
		</div>
	);
};

export default WithdrawLayout;

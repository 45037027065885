import { useEffect, useRef } from 'react';
import { CustomButton } from '../common/buttons';
import { WhaleMaintainIcon } from '../common/icons';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { ROUTES } from '../../types/routes';

const ErrorLayout = () => {
	const { t } = useTranslation();

	const routeError = useRouteError();

	const navigate = useCustomNavigate();

	const buttonRef = useRef(null);

	const handleRefreshClick = () => {
		window.location.reload();
	};

	useEffect(() => {
		if (
			window.location.pathname.includes('payments') &&
			//eslint-disable-next-line
			(routeError as any)?.code === 'ID_NOT_EXIST'
		) {
			sessionStorage.setItem('operationStatus', 'mfaExpired');
			navigate(ROUTES.WITHDRAW.SUMMARY);
		}
	}, []);

	return (
		<>
			<div className={'component'}>
				<div className={'innerComponent'}>
					<WhaleMaintainIcon className={''} />
					{
						//eslint-disable-next-line
						(routeError as any)?.code === 'ID_NOT_EXIST' ? (
							<>
								<h1 className={'maintainHeaderBody'}>
									{t('errorWidgetStateDoesNotExist')}
								</h1>
							</>
						) : (
							<>
								<h1 className={'maintainHeaderText'}>
									{t('errorHeader')}
								</h1>
								<h1 className={'maintainHeaderBody'}>
									{
										(routeError as any)?.code //eslint-disable-line @typescript-eslint/no-explicit-any
									}
								</h1>
								<h1 className={'maintainHeaderBody'}>
									{
										(routeError as any).message //eslint-disable-line @typescript-eslint/no-explicit-any
									}
								</h1>
							</>
						)
					}
					<CustomButton
						buttonRef={buttonRef}
						text={t('registrationMaintainButton') as string}
						type={'submit'}
						onClick={handleRefreshClick}
						className={'startButton'}
					/>
				</div>
			</div>
		</>
	);
};

export default ErrorLayout;

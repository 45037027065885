import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Whale } from '../../common/icons';

const WithdrawSummaryLayout = () => {
	const { t } = useTranslation();

	useEffect(() => {
		setTimeout(() => {
			window.close();
		}, 3000);
	}, []);

	const mapOperationStatus = () => {
		const operationStatus = sessionStorage.getItem('operationStatus')?.toLowerCase();

		switch (operationStatus) {
			case 'success':
				return 'withdrawSummarySuccessHeader';
			case 'mfaexpired':
				return 'errorWidgetStateDoesNotExist';
			default:
				return 'withdrawSummaryFailureHeader';
		}
	};

	return (
		<>
			<div className={'component'}>
				<div className={'innerComponent'}>
					<Whale className={'redirectWhale'} />
					<h1 className={'redirectHeaderText'}>{t(mapOperationStatus())}</h1>
				</div>
			</div>
		</>
	);
};

export default WithdrawSummaryLayout;

import { useMutation } from '@tanstack/react-query';
import { TWithdrawBody } from '../../types/types';
import {
	fetchRejectOperation,
	fetchSendOtpCode,
	fetchWithdraw,
} from '../../api/payments';
import { ROUTES } from '../../types/routes';

export function useWithdrawMutation(navigate: (path: string) => void) {
	const {
		mutateAsync: withdraw,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['withdraw'],
		mutationFn: (data: TWithdrawBody) => fetchWithdraw(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data.nextPageId === 'finishPageId') {
				sessionStorage.setItem('operationStatus', 'success');
				window.location.replace(
					`/${window.location.pathname.split('/')[1]}/widget/payments/finish`
				);
			} else if (data.paymentId) {
				sessionStorage.setItem('operationStatus', 'success');
				window.location.replace(
					`/${window.location.pathname.split('/')[1]}/widget/payments/finish`
				);
			}
		},
		//eslint-disable-next-line
		onError: async (data: any) => {
			if (data?.code === 'ID_NOT_EXIST') {
				sessionStorage.setItem('operationStatus', 'mfaExpired');
				navigate(ROUTES.WITHDRAW.SUMMARY);
			}
		},
	});
	return {
		withdraw,
		error,
		isError,
		isPending,
	};
}

export function useRejectMutation(navigate: (path: string) => void) {
	const { mutateAsync: reject, isPending: isLoading } = useMutation({
		mutationKey: ['rejectOperation'],
		mutationFn: (data: { wstate: string }) => fetchRejectOperation(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data.nextPageId === 'finishPageId') {
				sessionStorage.setItem('operationStatus', 'failure');
				window.location.replace(
					`/${window.location.pathname.split('/')[1]}/widget/payments/finish`
				);
			}
		},
		//eslint-disable-next-line
		onError: async (data: any) => {
			if (data?.code === 'ID_NOT_EXIST') {
				sessionStorage.setItem('operationStatus', 'mfaExpired');
				navigate(ROUTES.WITHDRAW.SUMMARY);
			}
		},
	});
	return {
		reject,
		isLoading,
	};
}

export function useSendOtpCodeMutation(navigate: (path: string) => void) {
	const {
		mutateAsync: sendOtpCode,
		isPending,
		isError,
		error,
	} = useMutation({
		mutationKey: ['sendOtpCodeWithdraw'],
		mutationFn: (data: {
			wstate: string;
			identityType: string;
			navigate: (path: string) => void;
		}) => fetchSendOtpCode(data),
		onSuccess: async () => {
			navigate(ROUTES.WITHDRAW.TWOFA);
		},
		//eslint-disable-next-line
		onError: async (data: any) => {
			if (data?.code === 'ID_NOT_EXIST') {
				sessionStorage.setItem('operationStatus', 'mfaExpired');
				navigate(ROUTES.WITHDRAW.SUMMARY);
			}
		},
	});
	return {
		isError,
		error,
		sendOtpCode,
		isPending,
	};
}
